













import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ReportView from '@/components/helper/report-view.vue';

class PageReportRequest extends PageRequest {
    course: number = null;
    schedule: number = null;
    date_from: string = new Date().toISOString().substr(0, 4) + '-01-01';
    date_to: string = new Date().toISOString().substr(0, 10);
}

@Component({
    components: { ReportView },
})
export default class CourseClases extends ListBase {
    pagerequest: PageReportRequest = new PageReportRequest();
    entity: string = 'course';

    columns = [
        { value: 'code', text: 'Codigo', type: 'text', sortable: false },
        { value: 'name', text: 'Nombre de Curso', type: 'text', sortable: false },
        { value: 'duration_text', text: 'Duracion', type: 'text', sortable: false },
        { value: 'teacher', text: 'Ponente', type: 'text', sortable: false },
        { value: 'schedule_name', text: 'Horario', type: 'text', sortable: false },
        { value: 'start_date', text: 'Fecha Inicio', type: 'date', sortable: false },
        { value: 'end_date', text: 'Fecha Fin', type: 'date', sortable: false },
        { value: 'capacity', text: 'Cupos', type: 'text', sortable: false },
        { value: 'enrolled_students', text: 'Matriculados', type: 'text', sortable: false },
    ];

    filterItems = [ 
        this.gItem({ type: 'object', prop: 'course', label: 'Curso', size: 4, hideDetails: true, loadOptions: false, onchange: this.changeCourse }),
        this.gItem({ type: 'object', prop: 'schedule', label: 'Horario', filterParam: 'course_id', filterVal: [], size: 2, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'object', prop: 'teacher', label: 'Ponente', size: 2, hideDetails: true, itemText: 'fullname', onchange: this.getpage }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Fechas Desde', size: 2, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 2, hideDetails: true, onchange: this.getpage }),
    ];

    /************* BOTONES DE ACCION ***************/
    changeCourse(id) {
        this.setItem(this.filterItems, 'schedule', 'filterVal', [id]);
        this.getpage();
    }

    async getpage() {
        let list: any = this.$refs.list
        await list.getpage();
    }
    
    created() {
        this.$store.dispatch({
            type: 'course/getAllActive'
        });
    }
    
}
